<template>
  <Page
    has-actions
  >
    <Card
      v-if="$can('read', 'settings.cli')"
      content-class="c-table"
    >
      <b-table
        :data="items"
        :total="items.length"
        :per-page="pagination.perPage"
        :current-page="pagination.page"
        paginated
        hoverable
      >
        <b-table-column
          v-slot="props"
          field="name"
          :label="this.$i18n.tc('name', 1)"
          width="300"
          header-class="sticky-header"
        >
          <span
            :class="{
              'pl-5': props.row.level == 2,                 
            }"
          >
            {{ props.row.name }}
          </span>
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="description"
          :label="this.$i18n.t('description')"
          header-class="sticky-header"
        >
          <span>
            {{ props.row.description }}
          </span>
        </b-table-column>
        <b-table-column
          v-if="$can('update', 'settings.cli')"
          v-slot="props"
          field="actions"
          width="140"
          header-class="sticky-header"
        >
          <b-button
            type="is-primary"
            size="is-small"
            icon-left="check"
            class="baseline"
            :loading="loadingKey==props.row.key"
            outlined
            @click="(props.row.arguments.length === 0 && props.row.options.length === 0 )?
              exeCommand(props.row.key) : showCommands(props.row)"
          >
            {{ $t("validate") }}
          </b-button>
          <!-- <span
            class="is-clickable"
            @click="(props.row.arguments.length === 0 && props.row.options.length === 0 )?
              exeCommand(props.row.key) : showCommands(props.row)"
          >
            <b-icon
              icon="check"
              type="is-success"
              size="is-small"
              class="baseline"
            />
            {{ $t("validate") }}
          </span> -->
        </b-table-column>
      </b-table>
      <Pagination
        :per-page="pagination.perPage"
        :per-pages="rowsPerPage"
        :total="items.length"
        :current-page="pagination.page"
        @update-per-page="val => (pagination.perPage = val)"
        @update-current-page="val => (pagination.page = val)"
      /> 
    </Card>
    <b-modal
      v-model="showCommandsModal"
      :width="520"
    >
      <Card>
        <div slot="header">
          <div class="is-size-5 px py-4">
            <b>{{ commandName }}</b>
          </div>
          <hr class="my-0">
        </div>
        <div v-if="selectArguments.length">
          <p class="has-text-weight-semibold mb-4">
            {{ $t("arguments") }}
          </p>
          <template
            v-for="(a,k) in selectArguments"
          >
            <b-input-validation  
              :key="k"           
              v-model="a.default"
              :label="a.label"
              :placeholder="a.description"
              type="text"
              size="is-small"
            />
          </template>
        </div>
        <div
          v-if="selectOptions.length"
        >
          <p class="has-text-weight-semibold mb-2">
            {{ $t("options") }}
          </p>
          <template v-for="opt in selectOptions">
            <b-tooltip
              :key="opt.check"
              :label="opt.description"
              type="is-primary"
              position="is-right"
              size="is-small"
              multilined
            >
              <b-checkbox 
                v-model="opt.check"
                class="ml-2"
              >
                <p class="is-size-7">
                  {{ opt.label }}
                </p>
              </b-checkbox>
            </b-tooltip>
          </template>
        </div>
        <b-button
          type="is-primary"
          size="is-small"
          class="mt-4"
          :loading="loading"
          :disabled="loading"
          @click="executeCommand(selectOptions, selectArguments)"
        >
          {{ $t("validate") }}
        </b-button>
        <b-button
          size="is-small"
          class="mt-4 ml-3"
          @click="showCommandsModal = false"
        >
          {{ $t("cancel") }}
        </b-button>
      </Card>
    </b-modal>
  </Page>
</template>

<script>
export default { 
  data() {
    return {
      rowsPerPage: [15, 30],
      pagination: { perPage: 15, page: 1 },
      items: [],
      loading: false,
      keepDays: null,
      showCommandsModal: false,
      selectArguments: [],
      selectOptions: [],
      commandKey: null,
      commandName: null,
      loadingKey: null,
    };
  },
  created() {
    this.updateTitle(this.$i18n.t("commands"));
    this.getItems();
  },  
  methods: {
    showCommands(item) {
      this.showCommandsModal = true;
      this.selectArguments = item.arguments;
      this.selectOptions = item.options;
      this.commandKey = item.key;
      this.commandName = item.name;
    },
    getItems() {
      if (!this.$can("read", "settings.cli")) return;
      this.loading = true;
      this.$axios
        .get(`/artisan`)
          .then(({ data })  => {
            let arr = [];
            for (let i = 0; i < data.list.length; i++) {
              const cat = data.list[i];
              cat.level = 1;
              arr.push(cat);
              if (cat.children)
                for (let j = 0; j < cat.children.length; j++) {
                  const subCat = cat.children[j];
                  subCat.level = 2;                
                  arr.push(subCat);
                }               
              }
            this.items = arr;
          })
        .catch(e => this.$root.clientError(e))
        .finally(() => (this.loading = false));
    },
    executeCommand(opt, arg) {
      let data = {};
      let key = this.commandKey;
      let options = [];
        let argumentlist = {}; 
        for (let i = 0; i < arg.length; i++) {
          const argumentKey = arg[i].key;
          const value = arg[i].default;
          argumentlist[argumentKey] = value;
        }
      data.arguments = argumentlist;
      
      if (opt.length > 0) {        
        for (let i = 0; i < opt.length; i++) {
          if (opt[i].check === true) {
            options.push(opt[i].key);            
          }
        }
        data.options = options;
      }
      this.loading = true;
      this.$axios
        .post("/artisan/" + key , {options: options, arguments: argumentlist,})
        .then(() => {
          this.showCommandsModal = false;
          this.$root.notify(
            this.$t("successfully_generated"),
            "is-success"
          );
        })
        .catch(e => this.$root.clientError(e))
        .finally(() => (this.loading = false));  
    },
     exeCommand(key) {
      this.loadingKey = key;
      this.$axios
        .post("/artisan/" + key)
        .then(() => {
          this.$root.notify(
            this.$t("successfully_generated"),
            "is-success"
          );
        })
        .catch(e => this.$root.clientError(e))
        .finally(() => (this.loadingKey = -1));
    },
  }
};
</script>